
import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {}
})
export default class Licitaciones extends Vue {
  private mediaUrl = process.env.VUE_APP_MEDIA_URL;
  private licitacion: any = "";
  private documentos: any = "";
  private paginaCargada = false;
  private nombreWeb: any = "";

  private mounted() {
    this.nombreWeb = this.$router.currentRoute.params.name;
    this.getLicitaciones();
  }

  private getLicitaciones() {
    this.$axios
      .get("licitaciones/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.licitacion = res.data;
        this.documentos = res.data.documentos;
        this.paginaCargada = true;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(process.env.VUE_APP_MEDIA_URL + "/" + item, target);
    } else {
      return;
    }
  }
}
