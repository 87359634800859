import { render, staticRenderFns } from "./Licitacion.vue?vue&type=template&id=1a659fe0"
import script from "./Licitacion.vue?vue&type=script&lang=ts"
export * from "./Licitacion.vue?vue&type=script&lang=ts"
import style0 from "./Licitacion.vue?vue&type=style&index=0&id=1a659fe0&prod&scope=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports