
import { Component, Vue } from "vue-property-decorator";
import Licitacion from "@/components/Licitaciones/Licitacion.vue";

@Component({
  components: {
    Licitacion
  }
})
export default class LicitacionView extends Vue {}
